<template>
  <!--begin::Header Nav-->
  <ul class="menu-nav">
    <li class="menu-item menu-item-submenu">
      <router-link class="menu-link" :to="{ name: 'intro' }">
        <span class="menu-text text-primary"
          ><i class="fas fa-home mr-3 text-primary"></i>
          {{ $t("MAINMENU.HOME") }}</span
        >
        <i class="menu-arrow"></i>
      </router-link>
    </li>
    <li
      class="menu-item menu-item-here menu-item-submenu menu-item-rel menu-item-here d-md-block d-lg-none"
      data-menu-toggle="click"
      aria-haspopup="true"
      v-if="
        this.currentUser.authorities &&
        !this.currentUser.authorities.includes('SCOUT')
      "
    >
      <a href="javascript:;" class="menu-link menu-toggle">
        <span class="menu-text text-primary"
          ><i class="fas fa-user-circle mr-3 text-primary"></i>
          {{ $t("MAINMENU.PS") }}</span
        >
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-left">
        <ul class="menu-subnav">
          <li class="menu-item" aria-haspopup="true">
            <router-link class="menu-link" to="/personal-space">
              <span class="svg-icon menu-icon">
                <i class="fas fa-address-card text-primary"></i>
              </span>
              <span class="menu-text text-primary">{{
                $t("MAINMENU.PSPROFILE")
              }}</span>
            </router-link>
          </li>
          <li class="menu-item" aria-haspopup="true">
            <router-link class="menu-link" to="/personal-space-activities">
              <span class="svg-icon menu-icon">
                <i class="fas fa-tasks text-primary"></i>
              </span>
              <span class="menu-text text-primary">{{
                $t("MAINMENU.PSACTIVITIES")
              }}</span>
              <!-- <span class="menu-label">
                <span class="label label-success label-rounded">2</span>
              </span> -->
            </router-link>
          </li>
          <li class="menu-item" aria-haspopup="true">
            <router-link class="menu-link" to="/personal-space-account">
              <span class="svg-icon menu-icon">
                <i class="fas fa-cog text-primary"></i>
              </span>
              <span class="menu-text text-primary">{{
                $t("MAINMENU.PSSETTINGS")
              }}</span>
            </router-link>
          </li> 
        </ul>
      </div>
    </li>
    <li
      :class="[
        is_promoSite ? '' : 'menu-item-here',
        'menu-item menu-item-submenu menu-item-rel',
      ]"
      data-menu-toggle="click"
      aria-haspopup="true"
      v-if="
        is_promoSite ||
        isProcessPublic ||
        isGroupPublic ||
        isChallenge ||
        isScenario
      "
    >
      <router-link
        v-if="is_promoSite"
        :to="{ name: 'processList' }"
        class="menu-link"
      >
        <span class="menu-text text-primary"
          ><i class="fas fa-th-large text-primary mr-3"></i>
          {{ $t("MAINMENU.EXPLORE") }}</span
        >
        <i class="menu-arrow"></i>
      </router-link>

      <a v-else href="javascript:;" class="menu-link menu-toggle">
        <span class="menu-text text-primary"
          ><i class="fas fa-th-large text-primary mr-3"></i>
          {{ $t("MAINMENU.EXPLORE") }}</span
        >
        <i class="menu-arrow"></i>
      </a>

      <div class="menu-submenu menu-submenu-classic menu-submenu-left">
        <ul class="menu-subnav">
          <li class="menu-item" aria-haspopup="true" v-if="isProcessPublic">
            <router-link class="menu-link" :to="{ name: 'processList' }">
              <span class="svg-icon menu-icon">
                <i class="fas fa-list text-primary"></i>
              </span>
              <span class="menu-text text-primary">{{
                isBandoZac ? "Casi ed esempi" : $t("MAINMENU.LISTPROCESSES")
              }}</span>
            </router-link>
          </li>
          <!--          <li class="menu-item" aria-haspopup="true" v-if="isPublicGroups">-->

          <!--Groups
          <li class="menu-item" aria-haspopup="true" v-if="isGroupPublic">
            <router-link
                class="menu-link"
                :to="{ name: 'groupList' }">
              <span class="svg-icon menu-icon">
                <i class="fas fa-users text-primary"></i>
              </span>
              <span class="menu-text text-primary" >{{$t("MAINMENU.LISTGROUPS")}}</span>
            </router-link>
          </li>
          -->

          <!--Community-->
          <li class="menu-item" aria-haspopup="true" v-if="isGroupPublic">
            <router-link class="menu-link" :to="{ name: 'groupsCommunity' }">
              <span class="svg-icon menu-icon">
                <i class="fas fa-users text-primary"></i>
              </span>
              <span class="menu-text text-primary">{{
                $t("MAINMENU.COMMUNITY")
              }}</span>
            </router-link>
          </li>

          <li class="menu-item" aria-haspopup="true" v-if="isChallenge">
            <router-link class="menu-link" :to="{ name: (isAgenda) ? 'driverDetail' : 'driverList', params: {id: this.driverId, driver_name: this.driverName}}">
              <span class="svg-icon menu-icon">
                <i class="fas fa-tags text-primary"></i>
              </span>
              <span class="menu-text text-primary">{{
                isBandoZac ? "Format di innovazione" : $t("MAINMENU.LISTCHALLENGES")
              }}</span>        
            </router-link>
          </li>
          <li class="menu-item" aria-haspopup="true" v-if="isScenario">
            <router-link class="menu-link" :to="{ name: 'scenarioList' }">
              <span class="svg-icon menu-icon">
                <i class="fas fa-map-marked-alt text-primary"></i>
              </span>
              <span class="menu-text text-primary">{{
                isBandoZac ? "Scenari di sviluppo" : $t("MAINMENU.LISTSCENARIOS")
              }}</span>
              <!-- <span class="menu-label">
                <span class="label label-success label-rounded">2</span>
              </span> -->
            </router-link>
          </li>
        </ul>
      </div>
    </li>
    <li
      class="menu-item menu-item-open menu-item-here menu-item-submenu menu-item-rel menu-item-open menu-item-here"
      data-menu-toggle="click"
      aria-haspopup="true"
      v-if="is_block1 || is_block2 || is_block3"
    >
      <a href="javascript:;" class="menu-link menu-toggle">
        <span class="menu-text text-primary">
          <i class="fas fa-info-circle text-primary mr-3"></i>
          {{
            is_promoSite ? $t("MAINMENU.SERVICES") : $t("MAINMENU.HOWITWORKS")
          }}
        </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-left">
        <ul class="menu-subnav">
          <li class="menu-item" aria-haspopup="true" v-if="is_block1">
            <router-link class="menu-link" :to="{ name: 'about' }">
              <span class="svg-icon menu-icon" v-if="!is_promoSite">
                <!--                <i :class="['text-primary', this.domain === this.promoSiteDomain ? '' : 'fas fa-list']"></i>-->
                <i class="text-primary fas fa-list"></i>
              </span>
              <span class="menu-text text-primary">
                {{
                  is_promoSite ? $t("MAINMENU.DEMO") : (isBandoZac ? "La vision" : $t("MAINMENU.PROJECT"))
                }}
              </span>
            </router-link>
          </li>
          <li class="menu-item" aria-haspopup="true" v-if="is_block2">
            <router-link class="menu-link" :to="{ name: 'how-it-works' }">
              <span class="svg-icon menu-icon" v-if="!is_promoSite">
                <!--                <i :class="['text-primary', this.domain === this.promoSiteDomain ? '' : 'fas fa-info-circle']"></i>-->
                <i class="text-primary fas fa-info-circle"></i>
              </span>
              <span class="menu-text text-primary">
                {{
                  is_promoSite
                    ? $t("MAINMENU.FEATURE")
                    : $t("MAINMENU.HOWTOJOIN")
                }}
              </span>
            </router-link>
          </li>
          <li class="menu-item" aria-haspopup="true" v-if="is_block3">
            <router-link class="menu-link" :to="{ name: 'faq' }">
              <span class="svg-icon menu-icon" v-if="!is_promoSite">
                <i class="text-primary fas fa-question-circle"></i>
              </span>
              <span class="menu-text">
                {{
                  is_promoSite ? $t("MAINMENU.PROJECTS") : $t("MAINMENU.FAQ")
                }}
              </span>
            </router-link>
          </li>

          <li
            class="menu-item"
            aria-haspopup="true"
            v-if="is_block4 && is_promoSite"
          >
            <router-link class="menu-link" :to="{ name: 'giochi' }">
              <span class="menu-text">
                {{ $t("MAINMENU.GIOCHI") }}
              </span>
            </router-link>
          </li>

          <li
            class="menu-item"
            aria-haspopup="true"
            v-if="domain.includes('partecipo-romagnafaentina.it')"
          >
            <router-link class="menu-link" :to="{ name: 'faq-custom' }">
              <span class="svg-icon menu-icon">
                <i class="fas fa-question-circle text-primary"></i>
              </span>
              <span class="menu-text">{{ $t("MAINMENU.FAQ") }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </li>
    <!-- Portal-->
    <li v-if="isActivePortal" class="menu-item menu-item-submenu">
      <router-link class="menu-link" :to="{ name: 'portal' }">
        <span class="menu-text text-primary"
          ><i class="fas fa-stream mr-3 text-primary"></i
          >{{ $t("MAINMENU.PORTAL") }}</span
        >
        <i class="menu-arrow"></i>
      </router-link>
    </li>
    <!--end: Portal-->
    <!-- choose language -->
    <li
      v-if="languages && languages.length > 1"
      class="menu-item menu-item-submenu"
    >
      <button @click="showChooseLanguafeModal" class="menu-link menu-toggle">
        <span class="menu-text"><i class="text-primary fas fa-globe"></i></span>
      </button>
      <ChooseLanguageModal
        ref="chooseLanguageModal"
        :languagesProp="languages"
      ></ChooseLanguageModal>
    </li>
    <!--end: choose language -->
    <li
      class="menu-item menu-item-submenu d-none d-lg-block"
      v-if="is_anonymous"
    >
      <router-link
        class="btn btn-primary"
        :to="{ name: 'login', query: { confirmedEmail: 'true' } }"
      >
        <span class="font-weight-bolder text-uppercase">{{
          $t("MAINMENU.LOGIN")
        }}</span>
      </router-link>
    </li>
    <li
      class="menu-item menu-item-submenu d-none d-lg-block"
      v-if="organizationRegAllowed && is_anonymous"
    >
      <router-link class="btn btn-primary" :to="{ name: 'register' }">
        <span class="font-weight-bolder text-uppercase">{{
          $t("MAINMENU.REGISTER")
        }}</span>
      </router-link>
    </li>
    <li
      class="menu-item menu-item-open menu-item-here menu-item-submenu menu-item-rel menu-item-open menu-item-here d-md-block d-lg-none"
      data-menu-toggle="click"
      aria-haspopup="true"
      v-if="is_anonymous"
    >
      <a href="javascript:;" class="menu-link menu-toggle">
        <span class="menu-text text-primary">
          <i class="fas fa-comments text-primary mr-3"></i>
          {{ $t("MAINMENU.JOINCOMMUNITY") }}</span
        >
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-left">
        <ul class="menu-subnav">
          <li
            class="menu-item"
            aria-haspopup="true"
            v-if="organizationRegAllowed && is_anonymous"
          >
            <router-link class="menu-link" :to="{ name: 'register' }">
              <span class="svg-icon menu-icon">
                <i class="fas fa-plus-square text-primary"></i>
              </span>
              <span class="menu-text text-primary font-weight-bolder">{{
                $t("MAINMENU.REGISTER")
              }}</span>
            </router-link>
          </li>
          <li class="menu-item" aria-haspopup="true" v-if="is_anonymous">
            <router-link
              class="menu-link"
              :to="{ name: 'login', query: { confirmedEmail: 'true' } }"
            >
              <span class="svg-icon menu-icon">
                <i class="fas fa-sign-in-alt text-primary"></i>
              </span>
              <span class="menu-text text-primary font-weight-bolder">{{
                $t("MAINMENU.LOGIN")
              }}</span>
              <!-- <span class="menu-label">
                <span class="label label-success label-rounded">2</span>
              </span> -->
            </router-link>
          </li>
        </ul>
      </div>
    </li>
    <li
      class="menu-item menu-item-submenu d-md-block d-lg-none"
      v-if="!is_anonymous"
    >
      <a @click="onLogout" class="menu-link">
        <span class="menu-text text-primary font-weight-bolder">{{
          $t("MAINMENU.LOGOUT")
        }}</span>
      </a>
    </li>
  </ul>
  <!--end::Header Nav-->
</template>

<script>
import ApiService from "../../../core/services/api.service";
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
import ChooseLanguageModal from "@/view/layout/header/partials/ChooseLanguageModal.vue";

export default {
  name: "KTMenu",
  data() {
    return {
      domain: window.location.host,
      promoSiteDomain: ["www.bipart.it", "stage.frontend.bipart.it"],

      organization: {},

      isGroupPublic: null,
      driverId: "",
      driverName: "",
    };
  },
  components: {
    ChooseLanguageModal,
  },
  computed: {
    ...mapGetters(["getOrganization", "currentUser"]),
    isBandoZac() {
      return window.location.host.includes('bandozac.bipart.it');
    },
    is_anonymous() {
      return (
        this.currentUser.authorities &&
        this.currentUser.authorities.includes("SCOUT")
      );
    },
    organizationRegAllowed() {
      return (
        this.currentUser && this.currentUser.selectedOrganizationRegAllowed
      );
    },
    is_block1() {
      return this.getOrganization.data && this.getOrganization.data.block1;
    },
    is_block2() {
      return this.getOrganization.data && this.getOrganization.data.block2;
    },
    is_block3() {
      return this.getOrganization.data && this.getOrganization.data.block3;
    },
    is_block4() {
      return this.getOrganization.data && this.getOrganization.data.block4;
    },
    is_promoSite() {
      // return (this.domain.split(":")[0] === this.promoSiteDomain);
      return this.promoSiteDomain.includes(this.domain.split(":")[0]);
    },
    isProcessPublic() {
      return (
        this.organization.countProcesses && this.organization.countProcesses > 0
      );
    },
    isAgenda() {
      return (
        this.getOrganization.data.countAgendas &&
        this.getOrganization.data.countAgendas === 1
      );
    },
    isChallenge() {
      return (
        this.getOrganization.data.countChallenges &&
        this.getOrganization.data.countChallenges > 0
      );
    },
    isScenario() {
      return (
        this.getOrganization.data.countScenarios &&
        this.getOrganization.data.countScenarios > 0
      );
    },
    isActivePortal() {
      return (
        this.getOrganization?.data &&
        this.getOrganization?.data?.umbrellaWorkspaceActive
      );
    },
  },
  created() {
    this.languages = this.getOrganization.data.languages;
    this.organization = this.getOrganization.data;
    this.getGroups();
    this.getAgendas();
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    showChooseLanguafeModal() {
      this.$refs["chooseLanguageModal"].open();
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    getGroups() {
      // provvisorio: sarà sostituito da filtro via api
      this.$isLoading(true);

      return new Promise((resolve, reject) => {
        let qs = "?profilePublic.equals=true";

        var endpoint = this.generateUrl("users/count", false);
        ApiService.get(this.$apiResource, endpoint + qs)
          .then((res) => {
            const count = res?.data || 0;
            this.isGroupPublic = count > 0;
            this.preloaderActive = false;
            this.$isLoading(false);
          })
          .catch(() => {
            this.preloaderActive = false;
            this.$isLoading(false);
            reject(0);
          });
      });
    },
    getAgendas() {
      if(this.isAgenda) {
      this.$isLoading(true);
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl("agendas", true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint + "?enabled.equal=true")
          .then(res => {            
            this.driverId = res.data[0]?.id || "";
            this.driverName = res.data[0]?.name || "";
            this.$isLoading(false);
          })
          .catch(() => {
            this.$isLoading(false);
            reject(0);
          });
        });
      }
    }
  },
};
</script>
